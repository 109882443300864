import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, ListItem, Chip } from '@mui/material';

const ticketStatuses = [
  { label: "All", value: "all" },
  { label: "Request Sent", value: "Request Sent" },
  { label: "Support in Progress", value: "Support in Progress" },
  { label: "Error - Password Reset", value: "Error - Password Reset" },
  { label: "Error - Password Incorrect", value: "Error - Password Incorrect" },
  { label: "Error - Verification Required", value: "Error - Verification Required" },
  { label: "Completed", value: "Completed" },
];

const FilterByStatus = React.memo(({ countList, onChange, ticketCount }) => {
  const [activeTab, setActiveTab] = useState("all");

  const handleTabChange = useCallback(
    (value) => {
      setActiveTab(value);
      if (onChange) {
        onChange(value); // Trigger callback with the selected tab value
      }
    },
    [onChange] // Removed activeTab from dependencies, as it's not required
  );

  const getCount = useCallback(
    (value) => {
      switch (value) {
        case "all":
          return ticketCount;
        case "Request Sent":
          return countList?.requestSent ?? 0;
        case "Support in Progress":
          return countList?.supportInProgress ?? 0;
        case "Error - Password Reset":
          return countList?.errorPasswordReset ?? 0;
        case "Error - Password Incorrect":
          return countList?.errorPasswordIncorrect ?? 0;
        case "Error - Verification Required":
          return countList?.errorVerificationRequired ?? 0;
        case "Completed":
          return countList?.completed ?? 0;
        default:
          return 0;
      }
    },
    [ticketCount]
  );

  return (
    <Box sx={{ padding: "0px" }}>
      {ticketStatuses?.map((status) => (
        <ListItem
          key={status.value}
          onClick={() => handleTabChange(status.value)}
          sx={{
            fontSize: "15px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "3px 0px",
            cursor: "pointer",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: activeTab === status.value ? "1px 6px" : "",
              borderRadius: "8px",
              backgroundColor: activeTab === status.value ? "#002180" : "transparent",
              color: activeTab === status.value ? "#ffffff" : "inherit",
            }}
          >
            {status.label}
          </Box>
          <Box>
            <Chip size="small" label={getCount(status.value)} />
          </Box>
        </ListItem>
      ))}
    </Box>
  );
});

FilterByStatus.propTypes = {
  onChange: PropTypes.func.isRequired, // Callback function for tab change
  ticketCount: PropTypes.number.isRequired, // Total ticket count for "All"
};

export default FilterByStatus;
