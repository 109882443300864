import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sidebarShow: 'responsive',
};

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setSidebarShow: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setSidebarShow } = sidebarSlice.actions;
export default sidebarSlice.reducer;
