import React from "react";

// mui component
import { Box } from "@mui/material";

// gif icons
import loaderGif from "../images/GIF-Loader-3.gif";

export const ContainerLoader = () => {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // position: "absolute"
            }}
        >
            <div style={{  top: '4px', height :"100%", minHeight:"540px" }}>
                <img className="loader-size-table" src={loaderGif} alt="no loader" />
            </div>
        </Box>
    );
};
