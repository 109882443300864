import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import { persistStore } from 'redux-persist';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable if using non-serializable values in Redux (like Dates)
    }),
  devTools: process.env.NODE_ENV !== 'production', // Enable Redux DevTools only in development
});

export const persistor = persistStore(store); // For persisted reducers

export default store;
