
import React, { useCallback } from "react";
import PropTypes from "prop-types"; // Define PropTypes for type-checking
import { FormControl, Select, MenuItem } from "@mui/material";
import { CustomPagination } from "../../utils";

const PaginationControl = React.memo(({ onPageChanged, pagination, SearchDisable, allTickets, changePageLimit }) => {
  // Memoize the changePageLimit handler
  const handleChangePageLimit = useCallback((event) => {
    changePageLimit(event);
  }, [changePageLimit]);

  return (
    <>
      {allTickets.count > 0 && (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {allTickets?.data?.length > 0 ? (
            <CustomPagination
              key="paginationnew"
              searchPage={pagination?.searchPage}
              SearchDisable={SearchDisable}
              totalRecords={allTickets.count}
              pageLimit={pagination?.pageLimit}
              onPageChanged={onPageChanged}
            />
          ) : (
            <div> </div>
          )}
          <div
            style={{
              textAlign: "right",
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <label>Items per page :</label>
            <FormControl sx={{ m: 1, minWidth: 50 }} size="small">
              <Select
                value={pagination?.pageLimit}
                name="limit"
                sx={{
                  "& .MuiSelect-select": {
                    padding: "4px 34px 4px 14px!important",
                    fontFamily: "Barlow",
                  },
                }}
                onChange={handleChangePageLimit}
              >
                {[10, 20, 50, 100].map((pageSize) => (
                  <MenuItem key={pageSize} value={pageSize}>
                    {pageSize}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      )}
    </>
  );
});

// Define PropTypes for type-checking
PaginationControl.propTypes = {
  onPageChanged: PropTypes.func.isRequired,
  pagination: PropTypes.shape({
    searchPage: PropTypes.number,
    pageLimit: PropTypes.number,
  }).isRequired,
  SearchDisable: PropTypes.bool,
  allTickets: PropTypes.shape({
    count: PropTypes.number.isRequired,
    data: PropTypes.array,
  }).isRequired,
  changePageLimit: PropTypes.func.isRequired,
};

PaginationControl.defaultProps = {
  SearchDisable: false,
};

export default PaginationControl;
