import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { encodeParamsToBase64 } from 'utils/base64';

export const loginUser = createAsyncThunk(
    'auth/loginUser',
    async ({ loginSupportUserApi, errorMessage, navigate, formData, }) => {
        try {
            const response = await loginSupportUserApi(formData)
            const { data } = response;
            if (data.error) {
                 errorMessage(data?.message, "error")
            } else {
                errorMessage(data?.message, "success")
                navigate("/")
                return data;
            }
        } catch (error) {
            return errorMessage()
        }
    }
);

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        isLoading: false,
        error: null,
        user: null,
    },
    reducers: {
        logout: (state) => {
            Cookies.remove('st_access_token');
            Cookies.remove('st');
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.isLoading = false;
                state.user = action.payload;
                Cookies.set('st_access_token', action?.payload?.accessToken, { expires: 30 });
                const lastToken = { lastToken: action?.payload?.accessToken, expriedToken: new Date() }
                const encodedTokenData = encodeParamsToBase64(JSON.stringify(lastToken));
                Cookies.set('st', encodedTokenData);
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
