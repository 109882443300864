import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { decodeBase64ToParams } from 'utils/base64';

// Helper function to clean tokens
const clearTokens = () => {
    Cookies.remove('st');
    Cookies.remove('st_access_token');
};

// Function to check if a token is expired based on its creation date
const isTokenExpired = (creationDate, expiryDays = 7) => {
    const tokenCreationDate = new Date(creationDate);
    const currentDate = new Date();
    const differenceInMs = currentDate - tokenCreationDate;
    const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24); // Difference in days
    return differenceInDays > expiryDays;
};

const ProtectedRoutes = ({ children, ...rest }) => {
    const token = Cookies.get("st_access_token");
    const lastToken = Cookies.get("st");
    const [isTokenExpiredState, setIsTokenExpiredState] = useState(false);
    const location = useLocation(); // Get current location

    let lastTokenUser;

    try {
        if (lastToken) {
            const decodedParams = decodeBase64ToParams(lastToken);
            lastTokenUser = JSON.parse(decodedParams);
        } else {
            clearTokens();
        }
    } catch (error) {
        clearTokens();
        lastTokenUser = null;
    }

    useEffect(() => {
        if (lastTokenUser?.expriedToken) {
            const isExpired = isTokenExpired(lastTokenUser.expriedToken);
            setIsTokenExpiredState(isExpired);
        }
    }, [lastTokenUser, token]);

    // Redirect to login if token is expired
    if (isTokenExpiredState) {
        clearTokens();
        return <Navigate to="/login" state={{ from: location }} />;
    }

    // Redirect to login if lastTokenUser is invalid or token mismatch
    if (!token || lastTokenUser?.lastToken !== token) {
        clearTokens();
        return <Navigate to="/login" state={{ from: location }} />;
    }

    // If token is valid, redirect to '/' page if currently at /login
    if (token && location.pathname === '/login') {
        return <Navigate to="/" />;
    }

    return token ? children : <Navigate to="/login" state={{ from: location }} />;
};

export default ProtectedRoutes;
