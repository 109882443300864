import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Link, Outlet } from "react-router-dom";

// mui components
import { styled } from "@mui/material/styles";
import {
    Box,
    CssBaseline,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    ListItemIcon
} from "@mui/material";

// custom components
import Logo from "../../images/logo.png";
import "../../pages/dashboard/index.scss";
import { logout } from "../../redux/features/loginSlice";
import { ConfirmationNumber, Logout } from "@mui/icons-material";
import MuiDrawer from '@mui/material/Drawer';
import "./index.scss";
const drawerWidth = 190;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer)(({ theme }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    variants: [
        {
            style: {
                ...openedMixin(theme),
                '& .MuiDrawer-paper': openedMixin(theme),
            },
        },
        {
            style: {
                ...closedMixin(theme),
                '& .MuiDrawer-paper': closedMixin(theme),
            },
        },
    ],
}),
);

const tabs = ['Tickets'];

const SidebarDrawer = () => {
    const dispatch = useDispatch();
    const [resolution, setResolution] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const handleCloseMenuLogOut = useCallback(() => {
        dispatch(logout());
    }, [dispatch]);

    useEffect(() => {
        const handleResize = () => {
            setResolution({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <Box sx={{ display: 'flex', height: '100vh' }}>
            <CssBaseline />
            <Drawer
                sx={{
                    width: resolution.width <= 768 ? 0 : drawerWidth,
                    flexShrink: 0, "& .MuiDrawer-paper": {
                        backgroundColor: "#3C4B64", color: "#fff",
                    },
                }} variant="permanent">
                <DrawerHeader sx={{ padding: "7px" }}>
                    <img src={Logo} className="sidebar-logos" alt="" />
                </DrawerHeader>
                <List className="admin-drawer">
                    {tabs.map((text, index) => (
                        <ListItem key={text} disablePadding sx={{ display: 'block' }}>
                            <ListItemButton
                                sx={[
                                    { minHeight: 48, px: 2.5 },
                                    { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' },
                                    { backgroundColor: '#002180', color: '#fff', '&:hover': { backgroundColor: '#002180', } },
                                ]}>
                                <ListItemIcon sx={[{ minWidth: 0, justifyContent: 'center' }, { mr: 'auto' }]}>
                                    <ConfirmationNumber sx={{ color: "#fff" }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={text}
                                    sx={{ opacity: 1, mt: 1, textAlign: 'center', marginTop: 0 }} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                    <ListItem disablePadding sx={{ display: 'block', position: "absolute", }} className="admin-drawer-logout">
                        <ListItemButton
                            onClick={handleCloseMenuLogOut}
                            disableripple="true"
                            component={Link}
                            to="/login"
                            sx={[
                                { minHeight: 48, px: 2.5 },
                                { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' },
                            ]}>
                            <ListItemIcon sx={[{ minWidth: 0, justifyContent: 'center' }, { mr: 'auto' }]}>
                                <Logout sx={{ color: "#fff" }} />
                            </ListItemIcon>
                            <ListItemText
                                primary={"Logout"}
                                sx={{ opacity: 1, mt: 1, textAlign: 'center', marginTop: 0 }} />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
            <Outlet />
        </Box>
    );
};

export default React.memo(SidebarDrawer);

