import React from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

const statusColors = {
  "Request Sent": { color: 'orange', label: "Request Sent" },
  "Support in Progress": { color: 'yellow', label: "Support in Progress" },
  "Error - Password Incorrect": { color: '#BC0000', label: "Error - Password Incorrect" },
  "Error - Password Reset": { color: '#BC0000', label: "Error - Password Reset" },
  "Error - Verification Required": { color: '#BC0000', label: "Error - Verification Required" },
  "Completed": { color: 'green', label: "Completed" }
};

const TicketStatus = ({ status }) => {
  const { color, label } = statusColors[status] || {};

  return (
    <Stack direction="row" spacing={1}>
      <Chip
        label={`${label}`}
        size="small"
        style={{
          backgroundColor: color,
          color: color === "yellow" ? "#d1c4c4" : '#fff',
          fontWeight: "bold",
          fontSize: "12px",
        }}
      />
    </Stack>
  );
};

export default React.memo(TicketStatus);

