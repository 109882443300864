// npm packages
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';

// icons ,mui icons components
import Logo from "../../images/logo.png";
import { Button, Checkbox, FormControlLabel } from '@mui/material';

// bootstrap and mui components
import { Row, Col, Container, } from 'react-bootstrap';
import {
  TextValidator,
  ValidatorForm
} from 'react-material-ui-form-validator';

// custom components
import { loginUser } from '../../redux/features/loginSlice';
import { useApiCollection } from 'hooks/useApiCollection';
import useAlertMessage from 'hooks/useAlertMessage';
import { AlertMessage } from 'utils';

// css styles
import './index.css';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loginSupportUserApi } = useApiCollection();
  const { alertMessage, errorMessage, hideAlert } = useAlertMessage();
  const { isLoading } = useSelector((state) => state.auth);
  const token = Cookies.get('st_access_token');
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleLogin = (e) => {
    dispatch(loginUser({ loginSupportUserApi, errorMessage, navigate, formData }))
  }

  useEffect(() => {
    if (token) {
      navigate('/');
    }
  }, [token])

  return (
    <>
      <div className="c-app c-default-layout flex-row align-items-center" style={{ marginTop: "100px" }}>
        <Helmet>
          <title>Support Login</title>
          <body className="Login-Bg" />
        </Helmet>
        <Container>
          <div className="Login-Outer-Padding">
            <Row className="vertical-center w-100">
              <Col md={6} className=' w-100'>
                <div className="Login-Left-Text center">
                  <Link to="/"><img src={Logo} className="logo-size" alt="" /></Link>
                </div>
              </Col>
              <Col md={6} className=' w-100'>
                <div className="Login-Right-Css">
                  <div className="center"> <h2 style={{ marginTop: "0px" }}
                    className="Login-Heading">
                    Support Login
                  </h2></div>
                  <ValidatorForm onSubmit={handleLogin} >
                    <Row>
                      <Col md="12">
                        <div className="form-group login-wrapper error-msg-fix" >
                          <label>Email</label>
                          <TextValidator
                            variant="outlined"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            validators={['required', 'isEmail']}
                            errorMessages={['this field is required', 'email is not valid']}
                          />
                        </div>
                      </Col>
                      <Col md="12">
                        <div className="form-group login-wrapper error-msg-fix">
                          <label>Password</label>
                          <TextValidator
                            variant="outlined"
                            type="password"
                            name="password"
                            value={formData.password}
                            onChange={handleInputChange}
                            validators={['required']}
                            errorMessages={['this field is required']}
                          />
                        </div>
                      </Col>
                      <Col md="12">
                        <Row className="align-items-center keep-login-label">
                          <Col md="12" className="text-left">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="checkedB"
                                  color="primary"
                                />
                              }
                              label="Keep me logged in"
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col md="12">
                        <Button
                          disabled={isLoading}
                          variant="contained"
                          type="submit"
                          className={isLoading ? 'full-width login-btn-disabled' : "full-width login-btn"}
                        >
                          {isLoading ? "Processing......" : "Login"}
                        </Button>
                      </Col>
                    </Row>
                  </ValidatorForm>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      {/* alert message */}
      <AlertMessage
        open={alertMessage.openAlert}
        message={alertMessage.message}
        severity={alertMessage.alertSeverity}
        closeCall={hideAlert}
      />
    </>
  )
}

export default Login;