import { combineReducers } from '@reduxjs/toolkit';
import { authSlice, sidebarSlice, supportTicketSlice, timeSlice } from './features';
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";

const timePersistConfig = {
  key: 'timeManagement',
  storage,
};

const authPersistConfig = {
  key: 'auth',
  storage,
};

const rootReducer = combineReducers({
  Sidebar: sidebarSlice,
  auth: persistReducer(authPersistConfig, authSlice),
  supportTickets: supportTicketSlice,
  timeManagement: persistReducer(timePersistConfig, timeSlice)
});

export default rootReducer;
