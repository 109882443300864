
import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  styled,
  tableCellClasses,
  TableSortLabel,
} from '@mui/material';
import { ContainerLoader } from '../../utils/ContainerLoader';
import { HiOutlineArrowNarrowDown } from 'react-icons/hi';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
    fontFamily: 'Barlow !important',
    fontWeight: 'bold',
    textAlign: 'left',
    position: 'relative',
    minWidth: 'max-content',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
    fontFamily: 'Barlow !important',
    textAlign: 'left',
    color: '#3C4E6A',
    borderBottom: '1px solid #CCCCCC',
    width: 'auto',
    padding: '12px 15px',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const DataTable = memo(({ data, isLoading, cellConfig, sortConfig, onSort }) => {
  const handleRotateIcon = useCallback(
    (field) => {
      if (onSort) {
        onSort(field);
      }
    },
    [onSort]
  );

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          minWidth: data.length === 0 || isLoading ? 1236 : 900,
          position: 'relative',
        }}
        aria-label="customized table"
      >
        {/* Table Header */}
        <TableHead>
          <StyledTableRow>
            {cellConfig.map((cell, index) => (
              <StyledTableCell key={index}>
                <div style={{ width: '100%', minWidth: 'max-content' }}>
                  {cell.sortable ? (
                    <TableSortLabel
                      sx={{
                        color: 'white !important',
                        '& .MuiTableSortLabel-icon': {
                          color: 'white !important',
                          opacity: 1,
                          transition: 'none',
                        },
                      }}
                      active={sortConfig?.currentColumn === cell?.field}
                      direction={sortConfig?.currentColumn === cell?.field ? sortConfig?.direction : 'asc'}
                      onClick={() => handleRotateIcon(cell?.field)}
                      IconComponent={HiOutlineArrowNarrowDown}
                    >
                      {cell.headerName}
                    </TableSortLabel>
                  ) : (
                    cell.headerName
                  )}
                </div>
              </StyledTableCell>
            ))}
          </StyledTableRow>
        </TableHead>
        {/* Table Body */}
        {isLoading ? (
          <ContainerLoader />
        ) : (
          <TableBody>
            {data?.length > 0 ? (
              data.map((item, index) => (
                <StyledTableRow key={index}>
                  {/* Dynamic Table Cells */}
                  {cellConfig.map((cell, cellIndex) => {
                    const value = item[cell.field];
                    return (
                      <StyledTableCell key={cellIndex}>
                        <div style={{ width: '100%', minWidth: 'max-content' }}>
                          {cell?.render ? cell?.render(value, item) : value}
                        </div>
                      </StyledTableCell>
                    );
                  })}
                </StyledTableRow>
              ))
            ) : (
              <TableRow>
                <TableCell component="th" scope="row" align="left">
                  <h4>No Data Found</h4>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
});

DataTable.propTypes = {
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  cellConfig: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      headerName: PropTypes.string.isRequired,
      sortable: PropTypes.bool,
      render: PropTypes.func,
    })
  ).isRequired,
  sortConfig: PropTypes.shape({
    currentColumn: PropTypes.string,
    direction: PropTypes.oneOf(['asc', 'desc']),
  }),
  onSort: PropTypes.func.isRequired,
};

DataTable.defaultProps = {
  isLoading: false,
  sortConfig: { currentColumn: '', direction: 'asc' },
};

export default DataTable;
