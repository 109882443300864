
import './index.scss'
import React, { useState, useEffect, useCallback } from 'react';
import { Cancel } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box, Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useApiCollection } from '../../hooks/useApiCollection';
import { assignedToTicket, getAllSupportTicket, getAllUsers, getCountList, getSupportUsers, startWorking, viewLogs } from '../../redux/features/supportTicketSlice';
import useAlertMessage from 'hooks/useAlertMessage';
import { AlertMessage, DateTimeFormat, TicketStatus } from 'utils';
import DataTableModal from './logsModal';
import { FilterDrawer, PageHeader, DataTable, PaginationControl, CustomButton } from '../../components';

const drawerWidth = 190;

const ContentLayout = ({ children, sx }) => (
    <Box
        sx={{
            width: '100%',
            flexGrow: 1,
            padding: '20px',
            overflowY: 'auto',
            backgroundColor: '#f4f4f4',
            height: '100vh',
            marginLeft: `56px`,
            ...sx
        }}>
        <Grid container sx={{ width: '100%' }}>
            {children}
        </Grid>
    </Box>
);

const TicketDshboard = () => {
    const dispatch = useDispatch();
    const { getAllSupportTicketApi, getCountAPI, assignedToTicketAPI, getAllUsersAPI, getSupportUsersAPI, startWorkingAPI, viewLogsAPI } = useApiCollection();
    const [formData, setFormData] = useState({
        eventEndDate: null,
        eventStartDate: null,
        eventName: null,
        users: null,
        status: "all",
    })
    const [pagination, setPagination] = useState({
        searchPage: 0,
        skip: 0,
        pageLimit: 10
    });
    const [modalPagination, setModalPagination] = useState({
        searchPage: 0,
        skip: 0,
        pageLimit: 10,
    });
    const { alertMessage, errorMessage, hideAlert } = useAlertMessage();
    const { countList, isLoading, allTickets, allUsers, support_users, isLoadingLogs, logsData } = useSelector((state) => state?.supportTickets);
    const { user } = useSelector((state) => state.auth);
    const [eventId, setEventId] = useState(null);
    const [isOpenLogs, setIsOpenLogs] = useState(false);
    const [timeUntilRefresh, setTimeUntilRefresh] = useState(120);
    const [isOpenAssign, setIsOpenAssign] = useState(false);
    const [isAssignedTo, setIsAssignedTo] = useState(null);
    const [assignData, setAssignData] = useState({
        assignedTo: null,
        supportId: null
    });
    const [state, setState] = useState({
        currentColumn: null,
        activeasc: false,
        activedesc: false,
        styleInactive: false,
        direction: 'asc',
    });

    const getAllSupportTickets = useCallback(async (pageSkip = 0, pagelimit = 10, status, sorting, openTwoDays) => {
        let params = {
            ...sorting,
            openLongerThan2Days: openTwoDays ?? false,
            eventStartDate: formData.eventStartDate,
            eventEndDate: formData.eventEndDate,
            skip: pageSkip,
            limit: pagelimit,
            userId: formData?.users?._id,
            eventName: formData.eventName,
            status: status === "all" ? null : status,
            role: user?.roles[0] === "ROLE_ADMIN-SUPPORT" ? "Admin" : null
        };
        await dispatch(getAllSupportTicket({ getAllSupportTicketApi, errorMessage, params }))
    }, [dispatch, pagination, formData]);

    const handleStartWorking = useCallback(async (item) => {
        const params = {
            id: item._id,
        };
        await dispatch(startWorking({ startWorkingAPI, errorMessage, params }))?.unwrap()
            .then((response) => {
                getAllSupportTickets(pagination.skip, pagination.pageLimit, formData.status);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [dispatch]);

    const onViewLogs = useCallback(async (item) => {
        setEventId(item?._id);
        setIsOpenLogs(true);
        const params = {
            id: item._id,
            skip: 0,
            limit: 10
        }
        await dispatch(viewLogs({ viewLogsAPI, errorMessage, params }))
    }, [dispatch]);

    const changePageLimit = async (e) => {
        setPagination({
            ...pagination,
            searchPage: 1,
            pageLimit: parseInt(e.target.value)
        })
        await getAllSupportTickets(0, parseInt(e.target.value), formData.status);
    };

    const onPageChanged = async (data) => {
        const { currentPage } = data;
        const updatedSkip = (currentPage - 1) * pagination.pageLimit;
        setPagination((prevPagination) => ({
            ...prevPagination,
            skip: updatedSkip,
        }));
        await getAllSupportTickets(updatedSkip, pagination.pageLimit, formData.status);
    };

    const SearchDisable = (value) => {
        setPagination({
            ...pagination,
            searchPage: value,
        })
    };

    const handleCloseLogs = () => {
        setIsOpenLogs(false);
        setEventId(null);
    }

    const changePageLimitModal = async (e) => {
        setModalPagination({
            ...modalPagination,
            searchPage: 1,
            pageLimit: parseInt(e.target.value)
        });
        const params = {
            id: eventId,
            skip: 0,
            limit: parseInt(e.target.value)
        }
        await dispatch(viewLogs({ viewLogsAPI, errorMessage, params }))
    };

    const onPageChangedModal = async (data) => {
        const { currentPage } = data;
        setModalPagination({
            ...modalPagination,
            skip: (currentPage - 1) * pagination.pageLimit
        });
        const params = {
            id: eventId,
            skip: (currentPage - 1) * pagination.pageLimit,
            limit: modalPagination.pageLimit
        }
        await dispatch(viewLogs({ viewLogsAPI, errorMessage, params }))
    };

    const openAssignUnassignModal = async (item) => {
        if (item) {
            setIsOpenAssign(true);
            setIsAssignedTo(item?.assignedTo ?? null);
            setAssignData({ ...assignData, supportId: item?._id });
            dispatch(getSupportUsers({ getSupportUsersAPI, errorMessage }))
        }
    };

    const onAssignedToTicket = async () => {
        const params = { ...assignData, assignedTo: assignData?.assignedTo?._id }
        dispatch(assignedToTicket({ assignedToTicketAPI, errorMessage, params })).unwrap().then((res) => {
            if (!res?.payload?.error) {
                setAssignData({ ...assignData, assignedTo: null, supportId: null });
                setIsOpenAssign(false);
                getAllSupportTickets(pagination.skip, pagination.pageLimit, formData.status);
            }
        })
    };

    const closeAssignUnassignModal = () => {
        setAssignData({ ...assignData, assignedTo: null, supportId: null });
        setIsOpenAssign(false);
    };

    const handleSelectUser = (event, value) => {
        if (value) {
            setAssignData({ ...assignData, assignedTo: value });
        } else {
            setAssignData({ ...assignData, assignedTo: null });
        }
    };

    const SearchDisableModal = (value) => {
        setModalPagination({
            ...modalPagination,
            searchPage: value,
        });
    };

    const handleFilterSubmit = async (status) => {
        if (status) {
            setFormData({ ...formData, status: status });
            await getAllSupportTickets(pagination.skip, pagination.pageLimit, status);
        } else {
            await getAllSupportTickets(pagination.skip, pagination.pageLimit, formData?.status);
        }
    };

    const handleRotateIcon = async (columnname) => {
        if (state.currentColumn === null) {
            await setState({ ...state, currentColumn: columnname });
        }
        if (state.currentColumn !== columnname) {
            await setState({
                ...state,
                activeasc: false,
                activedesc: false,
                currentColumn: columnname,
                styleInactive: false,
                direction: '',
            });
        }
        if (state.currentColumn === columnname) {
            if (
                !state.styleInactive &&
                !state.activeasc &&
                !state.activedesc &&
                state.currentColumn === columnname
            ) {
                await customSorting({ column: columnname, asc: true });
                setState({
                    ...state,
                    styleInactive: true,
                    currentColumn: columnname,
                    direction: 'asc',
                });
            }
            if (
                state.styleInactive &&
                !state.activeasc &&
                !state.activedesc &&
                state.currentColumn === columnname
            ) {
                await customSorting({ column: columnname, asc: false });
                await setState({
                    ...state,
                    styleInactive: true,
                    activeasc: true,
                    activedesc: false,
                    direction: 'desc',
                });
            } else if (
                state.styleInactive &&
                state.activeasc &&
                !state.activedesc &&
                state.currentColumn === columnname
            ) {
                await customSorting({ column: columnname, asc: true });
                await setState({
                    ...state,
                    currentColumn: columnname,
                    activeasc: false,
                    activedesc: false,
                    direction: 'asc',
                });
            }
        }
    };

    const customSorting = async (obj) => {
        if (obj?.column) {
            const sorting = {
                sortBy: obj.column === "vbdEventId" ? "event" : obj.column === "updatedAt" ? "created" : null,
                sortOrder: obj.asc ? "asc" : "desc",
            }
            await getAllSupportTickets(0, pagination.pageLimit, formData.status, sorting);
        }
    };

    const handleonChnage = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            getAllSupportTickets(0, pagination.pageLimit, formData.status);
        }
    };

    const handleFilterbyOpenTwoDay = async (value) => {
        const sorting = {
            sortBy: "",
            sortOrder: "",
        }
        await getAllSupportTickets(0, pagination.pageLimit, formData.status, sorting, value);
    }

    useEffect(() => {
        dispatch(getAllUsers({ getAllUsersAPI, errorMessage }))
        getAllSupportTickets(pagination.skip, pagination.pageLimit, formData.status);
        dispatch(getCountList({ getCountAPI, errorMessage, params: { role: user?.roles[0] === "ROLE_ADMIN-SUPPORT" ? "Admin" : null } }))
        const intervalId = setInterval(() => {
            setTimeUntilRefresh((prevTime) => {
                if (prevTime <= 1) {
                    getAllSupportTickets(pagination.skip, pagination.pageLimit, formData.status); // Fetch data every 2 minutes
                    return 120 // Reset the countdown
                }
                return prevTime - 1;
            })
        }, 1000)
        return () => clearInterval(intervalId);
    }, []);

    const cellConfig = React.useMemo(() => [
        {
            headerName: 'User Email',
            field: 'userId',
            sortable: false,
            render: (value) => <span>{value?.email}</span>,
        },
        {
            headerName: 'Event Name',
            field: 'vbdEventId',
            sortable: false,
            render: (value) => <span>{value?.event_name}</span>,
        },
        ...(user?.roles[0] === "ROLE_ADMIN-SUPPORT"
            ? [
                {
                    headerName: 'Assigned To',
                    field: 'assignedTo',
                    sortable: false,
                    render: (value) => <span>{value?.email ?? "-"}</span>,
                },
            ]
            : []),
        {
            headerName: 'Event Date',
            field: 'vbdEventId',
            sortable: true,
            render: (value) => <DateTimeFormat date={value?.dateformatted} />,
        },
        {
            headerName: 'Last Updated',
            field: 'updatedAt',
            sortable: true,
            render: (value) => <DateTimeFormat date={value} />,
        },
        {
            headerName: 'Status',
            field: 'status',
            sortable: false,
            render: (value) => <TicketStatus status={value} />,
        },
        {
            headerName: 'Actions',
            field: 'actions',
            render: (value, item) => (
                <div style={{ display: "flex", flexDirection: "row", }}>
                    {user?.roles[0] === "ROLE_ADMIN-SUPPORT" && <div>
                        <CustomButton
                            disabled={item?.status === "Completed" ? true : false}
                            component={Link}
                            title={!item?.assignedTo ? "Assign" : "Reassign"}
                            item={item}
                            onClickfunction={openAssignUnassignModal}
                        />
                    </div>}
                    {(user?.roles[0] === "ROLE_ADMIN-SUPPORT" ? (null
                    ) : (
                        item?.status === "Request Sent" || !item?.status === "Completed") ? (
                        <div style={{ width: "100px" }}>
                            <CustomButton
                                disabled={false}
                                title="Start Working"
                                item={item}
                                onClickfunction={handleStartWorking}
                            />
                        </div>) : (null))}
                    <div>
                        {item?.status === "Request Sent" || item?.status === "Completed" ?  null : <CustomButton
                            disabled={false}
                            title="View"
                            to={`/support-tickets-details/${item._id}`}
                        />}
                    </div>
                    <div style={{ width: "100px" }}>
                        <CustomButton
                            disabled={item?.status === "Request Sent" ?
                                user?.roles[0] === "ROLE_ADMIN-SUPPORT" ? false : true : false}
                            title="View logs"
                            item={item}
                            onClickfunction={onViewLogs}
                        />
                    </div>
                </div>
            ),
        },
    ], [user, handleStartWorking, onViewLogs]);



    return (
        <>
            <FilterDrawer
                drawerWidth={drawerWidth}
                countList={countList}
                formData={formData}
                handleStatusChange={handleFilterSubmit}
                handleKeyDown={handleKeyDown}
                handleOnChange={handleonChnage}
                onChange={handleFilterbyOpenTwoDay}

            />
            <ContentLayout>
                <PageHeader
                    title="All Recent Tickets"
                    subtitle="Tickets"
                    allUsers={allUsers}
                    setFormData={setFormData}
                    handleFilterSubmit={handleFilterSubmit}
                    formData={formData}
                    ticketCount={allTickets?.count ?? 0}
                />
                <Grid item xs={12} sx={{ marginTop: '88px', width: "100% !important" }}>
                    <DataTable
                        data={allTickets?.data?.length > 0 ? allTickets?.data : []}
                        isLoading={isLoading}
                        cellConfig={cellConfig}
                        sortConfig={state}
                        onSort={handleRotateIcon}
                    />
                    <PaginationControl
                        pagination={pagination}
                        onPageChanged={onPageChanged}
                        changePageLimit={changePageLimit}
                        allTickets={allTickets}
                        SearchDisable={SearchDisable}
                    />
                </Grid>
            </ContentLayout>
            {/* logs modal */}
            <DataTableModal
                isOpen={isOpenLogs}
                item={logsData?.data ?? []}
                closeModal={handleCloseLogs}
                isLoading={isLoadingLogs}
                pagination={modalPagination}
                changePageLimit={changePageLimitModal}
                onPageChanged={onPageChangedModal}
                totalRecords={logsData?.count ?? 0}
                SearchDisable={SearchDisableModal}
            />
            {/* alert message */}
            <AlertMessage
                open={alertMessage.openAlert}
                message={alertMessage.message}
                severity={alertMessage.alertSeverity}
                closeCall={hideAlert}
            />
            {/* modal for Assign Unassign ticket support  */}
            <Dialog
                onClick={(event) => event.stopPropagation()}
                maxWidth="sm"
                open={isOpenAssign}
                onClose={(e) => closeAssignUnassignModal(e)}
                sx={{
                    "& .MuiDialog-paper": {
                        width: "100%",
                    },
                }}>
                <DialogTitle sx={{ padding: "7px 11px" }} >
                    {!isAssignedTo ? "Assign Ticket to User" : "Reassign Ticket to User"}
                </DialogTitle>
                <DialogContent sx={{ padding: "15px 13px" }}>
                    <IconButton
                        aria-label="close"
                        onClick={(e) => closeAssignUnassignModal(e)}
                        sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            color: (theme) => theme.palette.grey[500],
                            p: 1,
                        }}>
                        <Cancel />
                    </IconButton>
                    <DialogContentText
                        sx={{
                            fontFamily: "Barlow",
                            fontWeight: "500",
                            fontSize: "18px",
                            color: "black",
                        }}>
                        <Grid container spacing={1}>
                            <Grid item size={{ xs: 12 }}>
                                <Typography sx={{ textAlign: "left!important", padding: "10px 0px", }} >
                                    Users
                                </Typography>
                                <Autocomplete
                                    fullWidth={true}
                                    id="checkboxes-tags-demo"
                                    options={support_users?.data ?? []}
                                    label="Users"
                                    onChange={handleSelectUser}
                                    getOptionLabel={(option) => option.email}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            sx={{
                                                "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root.Mui-error ": {
                                                    color: "#C4C4C4"
                                                },
                                            }}
                                            variant="outlined"
                                            label="Users"
                                            value={assignData?.assignedTo ?? null} />
                                    )} />
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => closeAssignUnassignModal()}
                        sx={{
                            backgroundColor: "#002986!important",
                            color: "white!important",
                            fontWeight: "bold",
                            fontFamily: "Barlow",
                            textTransform: "none",
                        }}>
                        Cancel
                    </Button>
                    <Button
                        onClick={(e) => onAssignedToTicket()}
                        sx={{
                            backgroundColor: "#002986!important",
                            color: "white!important",
                            fontWeight: "bold",
                            fontFamily: "Barlow",
                            textTransform: "none",
                        }}>
                        {!isAssignedTo ? "Assign" : "Reassign"}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default React.memo(TicketDshboard);
