import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';


const actionbutton = {
    backgroundColor: "#002986!important",
    color: "white!important",
    padding: "2px 5px!important",
    textTransform: "none!important",
    fontWeight: "bold",
    fontFamily: "Barlow!important",
    margin: "0 5px",
};
const actionbuttondisabled = {
    backgroundColor: " #E0E0E0 !important",
    color: "#BCBCBC !important",
    padding: "2px 5px!important",
    textTransform: "none!important",
    fontWeight: "bold",
    fontFamily: "Barlow!important",
    margin: "0 5px",
};

const CustomButton = ({
    disabled,
    size,
    variant,
    title,
    onClickfunction,
    to,
    item
}) => {
    return (
        <Button
            component={to === null ? null : Link}
            disabled={disabled}
            size={size}
            variant={variant}
            sx={!disabled ? actionbutton : actionbuttondisabled}
            onClick={() => onClickfunction(item)}
            to={to ? to : null}
        >
            {title}
        </Button >
    );
};

CustomButton.propTypes = {
    disabled: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
    title: PropTypes.node.isRequired,
    onClickfunction: PropTypes.func,
    to: PropTypes.string,  // for Link component only
    item: PropTypes.object // for item prop in case of Link component only
};

CustomButton.defaultProps = {
    disabled: false,
    size: 'small',
    variant: 'contained',
    onClickfunction: () => { },
    to: null,
    item: null
};

export default React.memo(CustomButton);
