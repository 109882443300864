
import React, { useState, useCallback, useMemo } from 'react';
import { Typography, Divider, Box, Modal, TextField, Button, Chip, Autocomplete, InputAdornment, IconButton, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid2';
import DatePicker from "react-datepicker";
import { AddCircleOutline, Clear, Event, Loop } from '@mui/icons-material';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import PropTypes from "prop-types";
import { useSelector } from 'react-redux';

const PageHeader = ({ title, allUsers, formData, setFormData, handleFilterSubmit }) => {
    const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
    const [isDateModalOpen, setIsDateModalOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedendDate, setSelectedEndDate] = useState(null);
    const [crossCheck, setCrossCheck] = useState(true);
    const [chipData, setChipData] = useState({ email: "", dateEndDate: "" });
    const { user } = useSelector((state) => state.auth);
    const {isLoading} = useSelector((state) => state?.supportTickets);

    const handleApplyEmail = useCallback(() => {
        setIsEmailModalOpen(false);
        setChipData((prev) => ({
            ...prev,
            email: formData?.users?.email,
        }));
        handleFilterSubmit("")
    }, [formData?.users?.email]);

    const handleApplyDate = useCallback(() => {
        setIsDateModalOpen(false);
        setChipData((prev) => ({
            ...prev,
            dateEndDate: `${moment(selectedDate).format("YYYY-MM-DD")} - ${moment(selectedendDate).format("YYYY-MM-DD")}`,
        }));
        handleFilterSubmit("")
    }, [selectedDate, selectedendDate]);

    const handleDeleteFilter = useCallback((filterKey) => {
        setChipData((prev) => ({ ...prev, [filterKey]: null }));
        if (filterKey === "dateEndDate") {
            setFormData((prev) => ({
                ...prev,
                eventStartDate: null,
                eventEndDate: null,
            }));
        } else {
            setFormData((prev) => ({
                ...prev,
                users: null
            }));
        }
        handleFilterSubmit("")
    }, []);

    const handleSelectUser = useCallback((event, value) => {
        setFormData((prev) => ({ ...prev, users: value || null }));
    }, []);

    const setStartDate = useCallback((dates) => {
        const [start, end] = dates;
        const datestart = moment(start).format("YYYY-MM-DDTHH:mm:ss");
        const dateend = end ? moment(end).format("YYYY-MM-DDTHH:mm:ss") : null;
        setFormData((prev) => ({
            ...prev,
            eventStartDate: datestart + ".000Z",
            eventEndDate: end ? dateend + ".000Z" : null,
        }));
        setSelectedDate(start);
        setSelectedEndDate(end);
        setCrossCheck(false);
    }, []);

    const clearDate = useCallback(() => {
        setFormData({ startDate: null, endDate: null });
        setSelectedDate(null);
        setSelectedEndDate(null);
        setCrossCheck(true);
    }, []);

    const formattedChipData = useMemo(() => {
        return {
            emailChip: chipData.email ? `User Email: ${chipData.email}` : "User Email",
            dateChip: chipData.dateEndDate ? `Event Date: ${chipData.dateEndDate}` : "Event Date",
        };
    }, [chipData]);

    return (
        <>
            <Grid xs={12} columnGap={1} sx={{ width: '78%', position: "fixed", backgroundColor: "#f4f4f4", zIndex: "1", paddingTop: "16px", top: "0px" }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
                        {title ?? ''}
                    </Typography>
                    <Typography
                        variant="h4"
                        sx={{
                            fontWeight: '600',
                            border: '2px solid #002583', // Border with color
                            borderRadius: '4px', // Border radius for rounded corners
                            padding: '2px', // Padding around the text
                            backgroundColor: '#f0f4f8', // Light background color
                            color: '#002583', // Text color
                            textAlign: 'center', // Center-align the text
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Optional shadow
                            marginTop: "-13px"
                        }}
                    >
                        {user?.name ?? ''}
                    </Typography>
                </Box>
                <Grid item xs={12}>
                    <Divider color="primary" sx={{ marginBottom: 1 }} />
                </Grid>
                <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
                    <Box sx={{ display: "flex", justifyContent: 'space-between' }}>
                        <Box sx={{ display: "flex", gap: "10px" }} >
                            <Chip
                                label={formattedChipData.emailChip}
                                onDelete={chipData.email ? () => handleDeleteFilter("email") : null}
                                color={chipData.email ? "primary" : "default"}
                                onClick={() => setIsEmailModalOpen(true)}
                                icon={chipData.email ? null : <AddCircleOutline />}
                            />
                            <Chip
                                label={formattedChipData.dateChip}
                                onDelete={chipData.dateEndDate ? () => handleDeleteFilter("dateEndDate") : null}
                                color={chipData.dateEndDate ? "primary" : "default"}
                                onClick={() => setIsDateModalOpen(true)}
                                icon={chipData.dateEndDate ? null : <AddCircleOutline />}
                            />
                        </Box>
                        <Box>
                            <Button
                                onClick={() => handleFilterSubmit(" ")}
                                size="small"
                                endIcon={isLoading ? (<CircularProgress color="inherit" size={20} />) : (<Loop />)}
                                disabled={isLoading}
                                loadingPosition="end"
                                variant="contained"
                            >
                                Refresh
                            </Button>
                        </Box>
                    </Box>
                </Typography>
                <Divider color="primary" sx={{ marginBottom: 1 }} />
            </Grid>
            {/* Email Modal */}
            <Modal open={isEmailModalOpen} onClose={() => setIsEmailModalOpen(false)}>
                <Box sx={{ ...modalStyles }}>
                    <Typography variant="h6" mb={2}>Filter by User Email</Typography>
                    <Autocomplete
                        fullWidth
                        disablePortal
                        id="checkboxes-tags-demo"
                        options={allUsers?.data ?? []}
                        label="Users"
                        onChange={handleSelectUser}
                        getOptionLabel={(option) => option.email}
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" label="Users" />
                        )}
                    />
                    <Button disabled={!formData?.users} variant="contained" sx={{ mt: 2 }} onClick={handleApplyEmail}>Apply</Button>
                </Box>
            </Modal>

            {/* Created Date Modal */}
            <Modal open={isDateModalOpen} onClose={() => setIsDateModalOpen(false)}>
                <Box sx={{ ...modalStyles }}>
                    <Typography variant="h6" mb={2}>Filter by Event Date</Typography>
                    <DatePicker
                        placeholderText="Search Date"
                        selected={selectedDate}
                        startDate={selectedDate}
                        endDate={selectedendDate}
                        monthsShown={2}
                        selectsRange
                        isClearable={true}
                        customInput={
                            <TextField
                                fullWidth
                                variant="outlined"
                                className="filter-input"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end" className="date-icon" >
                                            <div className="d-flex">
                                                {crossCheck ? (
                                                    <IconButton aria-label="toggle password visibility" edge="end" >
                                                        <Event className="text-dark" />
                                                    </IconButton>
                                                ) : (
                                                    <IconButton edge="end" onClick={() => clearDate()}>
                                                        <Clear className="text-dark" />
                                                    </IconButton>
                                                )}
                                            </div>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        }
                        onChange={(dates) => setStartDate(dates)} />
                    <Button disabled={!formData?.eventStartDate} variant="contained" sx={{ mt: 2 }} onClick={handleApplyDate}>Apply</Button>
                </Box>
            </Modal>
        </>
    );
};

const modalStyles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    padding: "20px",
    borderRadius: 2,
    boxShadow: 24,
    width: 400,
};

PageHeader.propTypes = {
    title: PropTypes.string,
    allUsers: PropTypes.arrayOf(PropTypes.object),
    formData: PropTypes.object,
    setFormData: PropTypes.func,
    handleFilterSubmit: PropTypes.func,
};

PageHeader.defaultProps = {
    title: "",
    allUsers: [],
    formData: {
        users: null,
        eventStartDate: null,
        eventEndDate: null,
    },
    setFormData: () => { },
    handleFilterSubmit: () => { },
};

export default React.memo(PageHeader);
