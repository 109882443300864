import React, { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Drawer, Divider, List, Box, Paper, IconButton, InputBase, ListItem, Chip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FilterByStatus } from '../../components';

const DrawerHeaderContent = ({ title }) => (
  <Box sx={{ padding: "9px", display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
    <div style={{ fontSize: "17px", fontWeight: "600" }}>{title}</div>
  </Box>
);

DrawerHeaderContent.propTypes = {
  title: PropTypes.string.isRequired,
};

// Search bar component
const SearchBar = React.memo(({ formData, placeholder, handleKeyDown, handleOnChange }) => (
  <Box sx={{ padding: "9px" }}>
    <Paper component="form" sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}>
      <IconButton type="button" sx={{ p: '4px 0px' }}>
        <SearchIcon />
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={placeholder}
        name="eventName"
        value={formData?.eventName}
        onChange={handleOnChange}
        onKeyDown={handleKeyDown}
      />
    </Paper>
  </Box>
));

SearchBar.propTypes = {
  formData: PropTypes.object,
  placeholder: PropTypes.string.isRequired,
  handleKeyDown: PropTypes.func.isRequired,
  handleOnChange: PropTypes.func.isRequired,
};

const TicketSectionTitle = React.memo(({ count, title }) => (
  <Box sx={{ padding: "0px" }}>
    <ListItem sx={{ fontSize: "15px", display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: "4px 0px" }}>
      <Box>{title}</Box>
      <Box>
        <Chip size="small" label={count ?? 0} />
      </Box>
    </ListItem>
  </Box>
));

TicketSectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.number,
};

// Section header component
const SectionHeader = React.memo(({ title, actionColor, icon }) => (
  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: "6px 0px 0px 0px" }}>
    <Box sx={{ fontSize: "17px", fontWeight: "600" }}>{title}</Box>
    <Box sx={{ color: actionColor }}>
      {icon}
    </Box>
  </Box>
));

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  actionColor: PropTypes.string,
  icon: PropTypes.node,
};

const FilterDrawer = ({
  drawerWidth,
  formData,
  handleKeyDown,
  handleOnChange,
  countList,
  handleStatusChange,
  onChange
}) => {
  const [resolution, setResolution] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [activeTab, setActiveTab] = useState(false);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setResolution({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Memoize drawer styles
  const drawerStyles = useMemo(
    () => ({
      width: resolution.width <= 600 ? 0 : drawerWidth,
      flexShrink: 0,
      "& .MuiDrawer-paper": {
        backgroundColor: "#F3FAFA !important",
        color: "black",
      },
    }),
    [resolution, drawerWidth]
  );

  const totalCount = useMemo(() => {
    if (countList?.statusCounts) {
      return Object?.values(countList?.statusCounts ?? null)?.reduce((sum, count) => sum + count, 0);
    } else {
      return 0;
    }
  }, [countList]);

  const handleTabChange = useCallback(() => {
    const newValue = !activeTab; // Toggle value
    setActiveTab(newValue);
    if (onChange) {
      onChange(newValue); // Trigger callback with updated value
    }
  }, [activeTab, onChange]);
  
  return (
    <Drawer
      sx={drawerStyles}
      className="drawer-container2"
      variant="permanent"
    >
      <DrawerHeaderContent title="Support Panel" />
      <Divider />
      <SearchBar
        placeholder="Search by Event Name"
        handleKeyDown={handleKeyDown}
        handleOnChange={handleOnChange}
        formData={formData}
      />
      <List sx={{ padding: "9px" }}>
        <Box sx={{ padding: "0px" }}>
          <Box sx={{ fontSize: "17px", fontWeight: "600" }}>Urgent Basis</Box>
          <ListItem
            sx={{
              fontSize: "15px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "4px 0px",
            }}
          >
            <Box
              onClick={handleTabChange}
              sx={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: activeTab ? "1px 6px" : "",
                borderRadius: "8px",
                backgroundColor: activeTab ? "#002180" : "transparent",
                color: activeTab ? "#ffffff" : "inherit",
              }}
            >
              Open from Two Days
            </Box>
            <Box>
              <Chip
                size="small"
                label={countList?.openLongerThan2DaysCount ?? 0}
              />
            </Box>
          </ListItem>
        </Box>
        <TicketSectionTitle
          title="Event within 48 hours"
          count={countList?.within48HoursCount ?? 0}
        />
        <SectionHeader
          title="Status"
          icon={<InfoOutlinedIcon sx={{ fontSize: "1.2rem" }} />}
        />
        <FilterByStatus
          countList={countList?.statusCounts ?? 0}
          onChange={handleStatusChange}
          ticketCount={totalCount ?? 0}
        />
      </List>
    </Drawer>
  );
};

FilterDrawer.propTypes = {
  drawerWidth: PropTypes.number,
  formData: PropTypes.object,
  handleKeyDown: PropTypes.func,
  handleOnChange: PropTypes.func,
  countList: PropTypes.object,
  handleStatusChange: PropTypes.func,
  handleopenLongerThan2Days: PropTypes.func,
  openLongerThan2Days: PropTypes.bool,
};

FilterDrawer.defaultProps = {
  drawerWidth: 240,
  formData: {},
  handleKeyDown: () => { },
  handleOnChange: () => { },
  countList: {},
  handleStatusChange: () => { },
  openLongerThan2Days: false,
  handleopenLongerThan2Days: () => { },
};

export default React.memo(FilterDrawer);
