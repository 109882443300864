import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getAllSupportTicket = createAsyncThunk(
    'supportTicket/getAllSupportTicket',
    async ({ getAllSupportTicketApi, errorMessage, params }) => {
        try {
            const response = await getAllSupportTicketApi(params);
            const { data } = response;
            if (data.error) {
                return errorMessage(data.message, "error");
            } else {
                return response.data;
            }
        } catch (error) {
            return errorMessage(error?.response?.data?.message ?? "", "error");
        }
    }
);

export const getSingleSupportTicket = createAsyncThunk(
    'supportTicket/getSingleSupportTicket',
    async ({ id, getSingleSupportTicketApi, errorMessage }) => {
        try {
            const response = await getSingleSupportTicketApi({ id: id })
            const { data } = response;
            if (data.error) {
                return errorMessage(data.message, "error");
            } else {
                return response.data;
            }
        } catch (error) {
            return errorMessage(error?.response?.data?.message ?? "", "error");
        }
    }
);

export const getAllUsers = createAsyncThunk(
    'supportTicket/getAllUsers',
    async ({ getAllUsersAPI, errorMessage }) => {
        try {
            const response = await getAllUsersAPI()
            const { data } = response;
            if (data.error) {
                return errorMessage(data.message, "error");
            } else {
                return response.data;
            }
        } catch (error) {
            return errorMessage(error?.response?.data?.message ?? "", "error");
        }
    }
);

export const getSupportUsers = createAsyncThunk(
    'supportTicket/getSupportUsers',
    async ({ getSupportUsersAPI, errorMessage }) => {
        try {
            const response = await getSupportUsersAPI()
            const { data } = response;
            if (data.error) {
                return errorMessage(data.message, "error");
            } else {
                return response.data;
            }
        } catch (error) {
            return errorMessage(error?.response?.data?.message ?? "", "error");
        }
    }
);

export const updateTicketStatus = createAsyncThunk(
    'supportTicket/updateTicketStatus',
    async ({ updateTicketStatusAPI, errorMessage, params }) => {
        try {
            const response = await updateTicketStatusAPI(params)
            const { data } = response;
            if (data.error) {
                return errorMessage(data.message, "error");
            } else {
                return errorMessage(data.message, "success");
            }
        } catch (error) {
            return errorMessage(error?.response?.data?.message ?? "", "error");
        }
    }
);

export const startWorking = createAsyncThunk(
    'supportTicket/startWorking',
    async ({ startWorkingAPI, errorMessage, params }) => {
        try {
            const response = await startWorkingAPI(params)
            const { data } = response;
            if (data.error) {
                return errorMessage(data.message, "error");
            } else {
                return errorMessage(data.message, "success");
            }
        } catch (error) {
            return errorMessage(error?.response?.data?.message ?? "", "error");
        }
    }
);

export const syncEvent = createAsyncThunk(
    'supportTicket/syncEvent',
    async ({ syncEventAPI, errorMessage, params }) => {
        try {
            const response = await syncEventAPI(params)
            const { data } = response;
            if (data.error) {
                return errorMessage(data.message, "error");
            } else {
                return errorMessage(data.message, "success");
            }
        } catch (error) {
            return errorMessage(error?.response?.data?.message ?? "", "error");
        }
    }
);

export const scanEvent = createAsyncThunk(
    'supportTicket/scanEvent',
    async ({ scanEventAPI, errorMessage, params }) => {
        try {
            const response = await scanEventAPI(params)
            const { data } = response;
            if (data.error) {
                return errorMessage(data.message, "error");
            } else {
                return errorMessage(data.message, "success");
            }
        } catch (error) {
            return errorMessage(error?.response?.data?.message ?? "", "error");
        }
    }
);

export const viewLogs = createAsyncThunk(
    'supportTicket/viewLogs',
    async ({ viewLogsAPI, errorMessage, params }) => {
        try {
            const response = await viewLogsAPI(params)
            const { data } = response;
            if (data.error) {
                return errorMessage(data.message, "error");
            } else {
                return data;
            }
        } catch (error) {
            return errorMessage(error?.response?.data?.message ?? "", "error");
        }
    }
);

export const assignedToTicket = createAsyncThunk(
    'supportTicket/assignedToTicket',
    async ({ assignedToTicketAPI, errorMessage, params }) => {
        try {
            console.log(params, 'params');
            const response = await assignedToTicketAPI(params)
            const { data } = response;
            if (data.error) {
                errorMessage(data.message, "error");
                return data;
            } else {
                errorMessage(data.message, "success");
                return data;
            }
        } catch (error) {
            return errorMessage(error?.response?.data?.message ?? "", "error");
        }
    }
);

export const getCountList = createAsyncThunk(
    'supportTicket/getCountList',
    async ({ getCountAPI, errorMessage, params }) => {
        try {
            const response = await getCountAPI(params)
            const { data } = response;
            if (data.error) {
                errorMessage(data.message, "error");
                return data;
            } else {
                return data;
            }
        } catch (error) {
            return errorMessage(error?.response?.data?.message ?? "", "error");
        }
    }
);

const supportTicket = createSlice({
    name: 'supportTicket',
    initialState: {
        allTickets: [],
        allUsers: [],
        ticketDetalis: null,
        isLoading: false,
        error: null,
        isLoadingLogs: false,
        isDisbaled: false,
        logsData: [],
        support_users: [],
        countList: null,
    },
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        // Get all support tickets
        builder
            .addCase(getAllSupportTicket.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getAllSupportTicket.fulfilled, (state, action) => {
                state.isLoading = false;
                state.allTickets = action.payload ?? [];
            })
            .addCase(getAllSupportTicket.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error;
            });
        // Get single support ticket
        builder
            .addCase(getSingleSupportTicket.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getSingleSupportTicket.fulfilled, (state, action) => {
                state.isLoading = false;
                state.ticketDetalis = action.payload ?? null;
            })
            .addCase(getSingleSupportTicket.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error;
            });
        // Get all users
        builder
            .addCase(getAllUsers.pending, (state) => {
                state.error = null;
            })
            .addCase(getAllUsers.fulfilled, (state, action) => {
                state.allUsers = action.payload ?? [];
            })
            .addCase(getAllUsers.rejected, (state, action) => {
                state.error = action.error;
            });
        // Update ticket status
        builder
            .addCase(updateTicketStatus.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(updateTicketStatus.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = action.payload ?? null;
            })
            .addCase(updateTicketStatus.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error;
            });
        // Sync event
        builder
            .addCase(syncEvent.pending, (state) => {
                state.isDisbaled = true;
                state.error = null;
            })
            .addCase(syncEvent.fulfilled, (state, action) => {
                state.isDisbaled = false;
                state.error = action.payload ?? null;
            })
            .addCase(syncEvent.rejected, (state, action) => {
                state.isDisbaled = false;
                state.error = action.error;
            });
        // Start working
        builder
            .addCase(startWorking.pending, (state) => {
                state.isDisbaled = true;
                state.error = null;
            })
            .addCase(startWorking.fulfilled, (state, action) => {
                state.isDisbaled = true;
                state.error = action.payload ?? null;
            })
            .addCase(startWorking.rejected, (state, action) => {
                state.isDisbaled = true;
                state.error = action.error;
            });
        // view logs 
        builder
            .addCase(viewLogs.pending, (state) => {
                state.error = null;
                state.isLoadingLogs = true;
            })
            .addCase(viewLogs.fulfilled, (state, action) => {
                state.isLoadingLogs = false;
                state.logsData = action.payload ?? [];
            })
            .addCase(viewLogs.rejected, (state, action) => {
                state.isLoadingLogs = false;
                state.error = action.error;
            });
        // scanEvent 
        builder
            .addCase(scanEvent.pending, (state) => {
                state.isDisbaled = true;
                state.error = null;
            })
            .addCase(scanEvent.fulfilled, (state, action) => {
                state.isDisbaled = false;
                state.error = action.payload ?? null;
            })
            .addCase(scanEvent.rejected, (state, action) => {
                state.isDisbaled = false;
                state.error = action.error;
            });
        // getSupportUsers
        builder
            .addCase(getSupportUsers.pending, (state) => {
                state.error = null;
            })
            .addCase(getSupportUsers.fulfilled, (state, action) => {
                state.support_users = action.payload ?? [];
            })
            .addCase(getSupportUsers.rejected, (state, action) => {
                state.error = action.error;
            });
        // assignedToTicket
        builder
            .addCase(assignedToTicket.pending, (state) => {
                state.error = null;
            })
            .addCase(assignedToTicket.fulfilled, (state, action) => {
                // state.support_users = action.payload?? [];
            })
            .addCase(assignedToTicket.rejected, (state, action) => {
                state.error = action.error;
            });
            // getCountList
            builder
            .addCase(getCountList.pending, (state) => {
                state.error = null;
            })
            .addCase(getCountList.fulfilled, (state, action) => {
                state.countList = action.payload?.data ?? [];
            })
            .addCase(getCountList.rejected, (state, action) => {
                state.error = action.error;
            });

    },
});

export const { reset } = supportTicket.actions;

export default supportTicket.reducer;
