import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// mui components
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Zoom from "@mui/material/Zoom";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function GrowTransition(props) {
    return <Zoom {...props} />;
}

function AlertMessage(props) {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        props.closeCall();
        setOpen(false);
    };

    return (
        <Snackbar
            open={open}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            onClose={handleClose}
            autoHideDuration={props.duration}
            TransitionComponent={GrowTransition}
        >
            <Alert
                onClose={props.closeCall}
                severity={props.severity}
                sx={{
                    color: "white!important",
                }}
            >
                <div dangerouslySetInnerHTML={{ __html: props.message }} />
            </Alert>
        </Snackbar>
    );
}

AlertMessage.propTypes = {
    open: PropTypes.bool,
    closeCall: PropTypes.func,
    duration: PropTypes.number,
    severity: PropTypes.string,
    message: PropTypes.string,
};

AlertMessage.defaultProps = {
    open: false,
    closeCall: () => { },
    duration: 2000,
    severity: "success",
    message: "",
};

export default AlertMessage;

