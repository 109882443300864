
import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogContent,
  DialogContentText,
  FormControl,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses
} from '@mui/material'
import { Cancel } from '@mui/icons-material'
import { ContainerLoader, CustomPagination, DateTimeFormat } from 'utils'

const StyledTableCells = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
    fontFamily: "Barlow !important",
    fontWeight: "bold",
    textAlign: "left",
    position: "relative",
    minWidth: "max-content",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
    fontFamily: "Barlow !important",
    textAlign: "left",
    color: "#3C4E6A",
    borderBottom: "1px solid #CCCCCC",
    width: "auto",
    padding: "12px 15px",
  },
}))

const StyledTableRows = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const DataTableModal = ({
  isOpen,
  item,
  closeModal,
  isLoading,
  pagination,
  changePageLimit,
  onPageChanged,
  totalRecords,
  SearchDisable,
}) => {
  const memoizedItem = useMemo(() => item, [item])

  const handleCloseModal = useCallback(() => {
    closeModal()
  }, [closeModal])

  const handleChangePageLimit = useCallback((e) => {
    changePageLimit(e)
  }, [changePageLimit])

  const renderTableRows = useCallback(() => {
    if (memoizedItem && memoizedItem.length > 0) {
      return memoizedItem.map((log, index) => (
        <StyledTableRows key={index}>
          <StyledTableCells component="td" scope="row">
            {log?.updatedBy?.email ?? "-"}
          </StyledTableCells>
          <StyledTableCells component="td" scope="row">
            {log?.status ?? "-"}
          </StyledTableCells>
          <StyledTableCells component="td" scope="row">
            <DateTimeFormat date={log?.createdAt ?? null} />
          </StyledTableCells>
        </StyledTableRows>
      ))
    }
    return (
      <StyledTableRows>
        <td colSpan={13} style={{ padding: "0rem" }}>
          <Typography variant="h6" sx={{ fontSize: "1.6rem", textAlign: "center" }}>
            No Log yet!
          </Typography>
        </td>
      </StyledTableRows>
    )
  }, [memoizedItem])

  const renderPagination = useMemo(() => {
    if (memoizedItem && memoizedItem.length > 0 && totalRecords > 0) {
      return (
        <CustomPagination
          key="paginationnew"
          searchPage={pagination?.searchPage}
          SearchDisable={SearchDisable}
          totalRecords={totalRecords}
          pageLimit={pagination?.pageLimit}
          onPageChanged={onPageChanged}
        />
      )
    }
    return null
  }, [memoizedItem, totalRecords, pagination, SearchDisable, onPageChanged])

  return (
    <Dialog
      scroll="body"
      fullWidth={true}
      maxWidth="md"
      open={isOpen}
      className="Modal_Box custom-pop-over"
      onClose={handleCloseModal}
    >
      <DialogContent>
        <IconButton
          aria-label="close"
          onClick={handleCloseModal}
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            color: (theme) => theme.palette.grey[500],
            p: 1,
          }}
        >
          <Cancel />
        </IconButton>
        <Typography
          variant="h6"
          sx={{
            width: "100%",
            maxWidth: "14rem",
            color: "#000059!important",
            textAlign: "left",
            fontWeight: "bold",
            fontSize: "1.6rem",
            fontFamily: "Barlow!important",
          }}
        >
          Logs
        </Typography>
        <DialogContentText>
          {isLoading ? (
            <ContainerLoader />
          ) : (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 600 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCells>User email</StyledTableCells>
                    <StyledTableCells>Status</StyledTableCells>
                    <StyledTableCells>Creation Date</StyledTableCells>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderTableRows()}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <br />
          {renderPagination}
          {memoizedItem && memoizedItem.length > 0 && (
            <div
              style={{
                textAlign: "right",
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <label>Items per page :</label>
              <FormControl
                sx={{
                  m: 1,
                  minWidth: 50,
                }}
                size="small"
              >
                <Select
                  defaultValue={pagination?.pageLimit}
                  value={pagination?.pageLimit}
                  name="limit"
                  sx={{
                    "& .MuiSelect-select": {
                      padding: "4px 34px 4px 14px!important",
                      fontFamily: "Barlow",
                    },
                  }}
                  onChange={handleChangePageLimit}
                >
                  <MenuItem value="10">10</MenuItem>
                  <MenuItem value="20">20</MenuItem>
                  <MenuItem value="50">50</MenuItem>
                  <MenuItem value="100">100</MenuItem>
                </Select>
              </FormControl>
            </div>
          )}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}

DataTableModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  item: PropTypes.array.isRequired,
  closeModal: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  pagination: PropTypes.shape({
    searchPage: PropTypes.number,
    pageLimit: PropTypes.number,
  }),
  changePageLimit: PropTypes.func.isRequired,
  onPageChanged: PropTypes.func.isRequired,
  totalRecords: PropTypes.number.isRequired,
  SearchDisable: PropTypes.bool.isRequired,
}

DataTableModal.defaultProps = {
  isOpen: false,
  item: [],
  closeModal: () => { },
  isLoading: false,
  pagination: {
    searchPage: 1,
    pageLimit: 10,
  },
  changePageLimit: () => { },
  onPageChanged: () => { },
  totalRecords: 0,
  SearchDisable: false,
}

export default React.memo(DataTableModal)