// npm packages
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// custom css 
import './App.css'
import "./common.css"

// custom componets 
import { ProtectedRoutes, SidebarDrawer } from './components';
import { Login, SupportTicketDetails, TicketDshboard } from './pages';

// Page not found component
const PageNotFound = () => {
  return (
    <div>
      <h1 style={{ textAlign: "center" }}>
        Page not Found
      </h1>
    </div>
  )
}

// component
const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<SidebarDrawer />}>
          <Route
            path="/"
            element={
              <ProtectedRoutes>
                <TicketDshboard />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/support-tickets-details/:id"
            element={
              <ProtectedRoutes>
                <SupportTicketDetails />
              </ProtectedRoutes>
            }
          />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
