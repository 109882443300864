import { createSlice } from '@reduxjs/toolkit';

// Define the initial state for time management
const initialState = {
  times: {},
};

const timeSlice = createSlice({
  name: 'timeManagement',
  initialState,
  reducers: {
    resetTime: (state, action) => {
      const { name, time } = action.payload;
      state.times[name] = time;
    },
    saveTime: (state, action) => {
      const { name, time } = action.payload;
      state.times[name] = time;
    },
  },
});

// Export actions
export const { resetTime, saveTime } = timeSlice.actions;

// Export the reducer
export default timeSlice.reducer;
