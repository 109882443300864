import { useAxios } from "./useAxios";

export const useApiCollection = () => {
    const { axiosRequest } = useAxios();

    /**
     * login support user api
     * @param {Object} params - parameters for login request
     * @returns {Promise<AxiosResponse<any>>} - response from the API call
     * @throws {Error} - if error occurs during the API call
     * @throws {AxiosError} - if axios request fails with an error
     */
    const loginSupportUserApi = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/auth/supportSignin", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    };

    /**
     * get all support tickets api
     * @param {Object} params - filter parameters for getting all tickets
     * @returns {Promise<AxiosResponse<any>>} - response from the API call
     * @throws {Error} - if error occurs during the API call
     * @throws {AxiosError} - if axios request fails with an error
     */
    const getAllSupportTicketApi = async (params = {}) => {
        try {
            const response = await axiosRequest(params?.role === "Admin" ? "/api/supportadmin/support/all" : "/api/support/all", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    };

    /**
     * get single support ticket api
     * @param {Object} params - ticket id for getting single ticket
     * @returns {Promise<AxiosResponse<any>>} - response from the API call
     * @throws {Error} - if error occurs during the API call
     * @throws {AxiosError} - if axios request fails with an error
     */
    const getSingleSupportTicketApi = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/support/getSingle", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    };

    /**
     * get password for a user
     * @param {Object} params - ticket id for hide and show users password
     * @returns {Promise<AxiosResponse<any>>} - response from the API call
     * @throws {Error} - if error occurs during the API call
     * @throws {AxiosError} - if axios request fails with an error
     */
    const accountDetailWithPlainPassAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/support/decryptAccPass", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    };

    /**
     * get all users api
     * @param {Object} params - filter parameters for getting all users
     * @returns {Promise<AxiosResponse<any>>} - response from the API call
     * @throws {Error} - if error occurs during the API call
     * @throws {AxiosError} - if axios request fails with an error
     */
    const getAllUsersAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/support/listAllUsers", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    };

    /**
     * update support ticket status api
     * @param {Object} params - tikcet id , status for update ticket status
     * @returns {Promise<AxiosResponse<any>>} - response from the API call
     * @throws {Error} - if error occurs during the API call
     * @throws {AxiosError} - if axios request fails with an error
     */
    const updateTicketStatusAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/support/updateStatus", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    };

    /**
     * start working on support tikcet api
     * @param {Object} params - tikcet id  for update ticket status
     * @returns {Promise<AxiosResponse<any>>} - response from the API call
     * @throws {Error} - if error occurs during the API call
     * @throws {AxiosError} - if axios request fails with an error
     */
    const startWorkingAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/support/startWorking", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    };

    /**
     * sync event api
     * @param {Object} params - evnt id for sync event
     * @returns {Promise<AxiosResponse<any>>} - response from the API call
     * @throws {Error} - if error occurs during the API call
     * @throws {AxiosError} - if axios request fails with an error
     */
    const syncEventAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/support/syncEvent", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    };

    /**
    * sync event api
    * @param {Object} params - event id  for sync event
    * @returns {Promise<AxiosResponse<any>>} - response from the API call
    * @throws {Error} - if error occurs during the API call
    * @throws {AxiosError} - if axios request fails with an error
    */
    const scanEventAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/support/scanEventTickets", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    };

    /**
      * view logs api
      * @param {Object} params - event id  
      * @returns {Promise<AxiosResponse<any>>} - response from the API call
      * @throws {Error} - if error occurs during the API call
      * @throws {AxiosError} - if axios request fails with an error
      */
    const viewLogsAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/support/allSupportLog", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    };

    const getSupportUsersAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("api/support/listAllUsers?isSupport=true", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    };

    const assignedToTicketAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/supportadmin/support/assignUser", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    };

    const getCountAPI = async (params = {}) => {
        try {
            const response = await axiosRequest(params?.role === "Admin" ? "/api/supportadmin/support/stats" : "/api/support/stats", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    };


    return {
        // api functions
        loginSupportUserApi,
        getAllSupportTicketApi,
        getSingleSupportTicketApi,
        accountDetailWithPlainPassAPI,
        getSupportUsersAPI,
        updateTicketStatusAPI,
        syncEventAPI,
        startWorkingAPI,
        scanEventAPI,
        viewLogsAPI,
        assignedToTicketAPI,
        getAllUsersAPI,
        getCountAPI
    };
};