// npm packages
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom'
import { RiFileCopy2Fill } from "react-icons/ri";

// mui icons  component
import { ArrowBack, Cancel, LibraryAddCheck, Visibility, VisibilityOff } from '@mui/icons-material';

// mui components
import {
    Button,
    ButtonGroup,
    Card,
    CardContent,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    IconButton,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tooltip,
    Typography,
    styled,
    tableCellClasses
} from '@mui/material';
import Grid from '@mui/material/Grid2';

// components
import useAlertMessage from 'hooks/useAlertMessage';
import { useApiCollection } from 'hooks/useApiCollection';
import { getSingleSupportTicket, scanEvent, syncEvent, updateTicketStatus } from '../../redux/features/supportTicketSlice';
import { AlertMessage, ConfirmationModal, ContainerLoader, DateTimeFormat, TicketStatus } from 'utils';
import LoadPassword from "../../images/load_password.svg"
import { HiDotsHorizontal } from 'react-icons/hi';
import TimerButton from 'utils/TimerButton';
import "./index.scss"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        fontSize: 16,
        fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const thclass = {
    fontWeight: "bold",
    width: "20rem",
};

const actionbutton = {
    borderRadius: " 5px",
    minWidth: "70px",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#002986 ",
    color: "white ",
    padding: "2px 5px ",
    textTransform: "none ",
    fontWeight: "bold ",
    fontFamily: "Barlow",
    margin: "0 5px ",
};

const diabledbutton = {
    borderRadius: " 5px",
    minWidth: "70px",
    display: "flex",
    justifyContent: "center",
    padding: "2px 5px!important",
    textTransform: "none!important",
    color: "white!important",
    fontWeight: "bold",
    fontFamily: "Barlow!important",
    margin: "0 5px",
};

const SupportTicketDetails = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const {
        accountDetailWithPlainPassAPI,
        updateTicketStatusAPI,
        syncEventAPI,
        scanEventAPI,
        getSingleSupportTicketApi
    } = useApiCollection();
    const { alertMessage, errorMessage, hideAlert } = useAlertMessage();
    const { isLoading, ticketDetalis, isDisbaled } = useSelector((state) => state?.supportTickets);
    const [isEmailCopy, setIsEmailCopy] = useState(null);
    const [hidePassword, setHidePassword] = useState(true);
    const [indexNum, setIndexNum] = useState('');
    const [loadPass, setLoadPass] = useState(false);
    const [dataLists, setDataLists] = useState({});
    const [eventId, setEventId] = useState(null);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isCopy, setIsCopy] = useState(false);
    const [statusTicket, setStatusTicket] = useState("");
    const [syncModal, setSyncModal] = useState(false);
    const [scanModal, setScanModal] = useState(false);
    const [parmater, setParmater] = useState({});

    const getSingleSupport = async () => {
        await dispatch(getSingleSupportTicket({ id, getSingleSupportTicketApi, errorMessage }))
    }

    const handleShowhiddenPassword = async (item) => {
        if (hidePassword && indexNum === item._id) {
            setHidePassword(false);
            setIndexNum('');
            setLoadPass(false);
        } else {
            try {
                setHidePassword(true);
                setIndexNum(item._id);
                setLoadPass(true);
                const response = await accountDetailWithPlainPassAPI({ id: item?.accountId._id, userId: item.userId?._id });
                const { data } = response;
                if (data.error) {
                    setHidePassword(false);
                    setIndexNum('');
                    setLoadPass(false);
                } else {
                    setDataLists(prevState => ({
                        ...prevState,
                        accountId: {
                            ...prevState.accountId,
                            password: data?.data?.password
                        }
                    }));
                    setLoadPass(false);
                }
            } catch (error) {
                setHidePassword(false);
                setIndexNum('');
                setLoadPass(false);
            }
        }
    };

    const handleTicketStatusUpdate = async () => {
        const params = {
            id: eventId,
            status: statusTicket,
        };
        await dispatch(updateTicketStatus({ updateTicketStatusAPI, errorMessage, params }))?.unwrap()
            .then((response) => {
                setIsOpenModal(false);
                setEventId(null);
                getSingleSupport()
            })
            .catch((error) => {
                setEventId(null);
            });
    };

    const handleScanModal = (data) => {
        if (data) {
            setScanModal(true);
            setParmater({
                ...parmater,
                scanevents: [data?.vbdEventId?._id],
                userId: data?.userId?._id
            })
        }
    }

    const handleCloseDeleteConfirmationModel = () => {
        setSyncModal(false);
        setParmater({});
        setScanModal(false);
    }

    const handleSyncModal = async (e, data) => {
        if (data) {
            setParmater({
                ...parmater,
                eventId: data?.vbdEventId?._id,
                userId: data?.userId?._id
            })
            setSyncModal(true);
        }
    }

    const syncAPiCall = async () => {
        await dispatch(syncEvent({ syncEventAPI, errorMessage, params: parmater }))?.unwrap()
            .then((res) => {
                getSingleSupport();
                handleCloseDeleteConfirmationModel();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const scanAPiCall = async () => {
        await dispatch(scanEvent({ scanEventAPI, errorMessage, params: parmater }))?.unwrap()
            .then((response) => {
                getSingleSupport()
                handleCloseDeleteConfirmationModel()
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleOpenModalChangeStates = (e, item) => {
        setIsOpenModal(true);
        setStatusTicket(item?.status === "Request Sent" ? "" : item?.status)
        setEventId(item?._id)
    }

    const handleCloseModalChangeStates = () => {
        setIsOpenModal(false);
        setEventId(null)
    }

    const handleBackdropClick = (event) => {
        event.stopPropagation();
    };

    const handleOnchnageTicketStatus = (event) => {
        setStatusTicket(event.target.value)
    }

    const handleNothing = () => {
        console.log("waiting for action..........");
    }

    const handleCopyToClipboardPassword = (content) => {
        const el = document.createElement('textarea');
        setIsCopy(true)
        el.value = content;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    };

    const handleCopyToClipboardEmail = (content) => {
        const el = document.createElement('textarea');
        setIsEmailCopy(true)
        el.value = content;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    };

    useEffect(() => {
        let interval;
        if (isEmailCopy || isCopy) {
            interval = setInterval(() => {
                setIsCopy(false)
                setIsEmailCopy(false);
            }, 2000);
        }
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [isEmailCopy, isCopy]);

    useEffect(() => {
        if (ticketDetalis !== null) {
            setDataLists(ticketDetalis?.data)
        }
    }, [ticketDetalis])

    useEffect(() => {
        getSingleSupport()
    }, [id])

    return (
        <>
            <Helmet>
                <title>Ticket Detalis</title>
            </Helmet>
            <Grid container spacing={2} sx={{width: "100%"}}>
                <Grid item xs={12} className="mui_grid" sx={{width: "100%"}}>
                    <Card
                        sx={{
                            width: "100%",
                            maxWidth: "100%",
                            height: "100%",
                            color: "#000059!important",
                            textAlign: "left",
                            padding: "20px 20px 20px 20px",
                            backgroundColor: "#fbfbfb;",
                        }}
                    >
                        <div className="vendortable-header">
                            <Typography
                                sx={{
                                    width: "100%",
                                    maxWidth: "31rem",
                                    color: "#000059!important",
                                    textAlign: "left",
                                    fontWeight: "bold",
                                    fontSize: "1.6rem",
                                    fontFamily: "Barlow!important",
                                }}
                                variant="h6"
                                align="left"
                            >
                                Ticket Details
                            </Typography>
                            <br />
                            <Grid
                                container
                                spacing={{ xs: 1, md: 1, lg: 1 }}
                                columns={{ xs: 4, sm: 8, md: 8 }}
                                sx={{ display: "contents" }}
                                className="button-align"
                            >
                                {dataLists?.status === "Completed" ? (null) : (
                                    <Grid
                                        item
                                        size={{ xs: 3.8, sm: 3.8, md: 3.8, lg: 3.8, xl: 3.8 }}
                                        className="align-buttons"
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                padding: "10px 10px",
                                                borderRadius: "4px",
                                                boxShadow:
                                                    "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
                                            }}
                                        >
                                            <Typography
                                                variant="p"
                                                align="center"
                                                sx={{
                                                    fontSize: "18px",
                                                    fontWeight: "600",
                                                    color: "#000000",
                                                }}
                                            >
                                                Actions
                                            </Typography>
                                            <ButtonGroup
                                                variant="contained"
                                                aria-label="Actions"
                                                sx={{
                                                    justifyContent: "space-evenly",
                                                    boxShadow: "none",
                                                    minWidth: "0px !important",
                                                    "& button": {
                                                        marginTop: {
                                                            xs: "20px",
                                                            sm: "20px",
                                                            md: "20px",
                                                            height: "max-content",
                                                        },
                                                    },
                                                    "& a": {
                                                        marginTop: {
                                                            xs: "20px",
                                                            sm: "20px",
                                                            md: "20px"
                                                        },
                                                        maxHeight: "37px"
                                                    },
                                                    flexWrap: {
                                                        xs: "wrap",
                                                        sm: "wrap",
                                                        md: "wrap",
                                                        lg: "nowrap",
                                                    }
                                                }}
                                            >
                                                <Button
                                                    size="small"
                                                    variant="contained"
                                                    className='actions-btn'
                                                    disabled={isLoading}
                                                    style={actionbutton}
                                                    onClick={(e) => handleOpenModalChangeStates(e, dataLists)}
                                                >
                                                    Change Status
                                                </Button>
                                                &nbsp;
                                                <TimerButton
                                                    className="actions-btn"
                                                    styles={{ actionbutton: actionbutton, diabledbutton: diabledbutton }}
                                                    apiCall={() => isLoading ? handleNothing : handleScanModal(dataLists)}
                                                    date={dataLists?.accountId?.nextRefresh ?? ""}
                                                    item={dataLists?.vbdEventId?._id}
                                                    id={`event-scan${dataLists?.vbdEventId?._id}`}
                                                    name="Scan"
                                                    time={0}
                                                    size="small"
                                                    timeinseconds={0}
                                                />
                                                &nbsp;
                                                <Button
                                                    onClick={(e) => handleSyncModal(e, dataLists)}
                                                    className='actions-btn'
                                                    size="small"
                                                    disabled={isLoading}
                                                    variant="contained"
                                                    style={actionbutton}
                                                >
                                                    Sync
                                                </Button>
                                            </ButtonGroup>
                                        </div>
                                    </Grid>
                                )}
                                <br />
                                <Grid item size={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}
                                    sx={{
                                        display: "flex",
                                        height: " 44px",
                                        justifyContent: {
                                            xs: "start",
                                            sm: "start",
                                            md: "start",
                                            lg: "end",
                                        }
                                    }}>
                                    <Button
                                        size="large"
                                        to='/'
                                        component={Link}
                                        variant="contained"
                                        className='refresh-btn'
                                        startIcon={<ArrowBack />}
                                    >
                                        Back
                                    </Button>
                                </Grid>
                            </Grid>

                        </div>
                        <CardContent className="plan-tbl" sx={{ position: "relative" , padding:"25px 0px 0px 0px" }}>
                            {isLoading ? (
                                <ContainerLoader />
                            ) : (
                                <TableContainer
                                    component={Paper}
                                    sx={{
                                        boxShadow: "none!important",
                                    }}
                                >
                                    <Table aria-label="contact view table">
                                        <TableBody>
                                            <StyledTableRow>
                                                <StyledTableCell
                                                    component="th"
                                                    scope="row"
                                                    sx={thclass}
                                                >
                                                    Team
                                                </StyledTableCell>
                                                <StyledTableCell>{dataLists?.accountId?.team ?? "-"}</StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow>
                                                <StyledTableCell
                                                    component="th"
                                                    scope="row"
                                                    sx={thclass}
                                                >
                                                    User Email
                                                </StyledTableCell>
                                                <StyledTableCell>{dataLists?.userId?.email ?? "-"}</StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow>
                                                <StyledTableCell
                                                    component="th"
                                                    scope="row"
                                                    sx={thclass}
                                                >
                                                    Username
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <div
                                                        style={{
                                                            width: "100%",
                                                            minWidth: "max-content",
                                                        }}
                                                    >
                                                        {dataLists?.accountId?.event?.email ?
                                                            dataLists?.accountId?.event?.email :
                                                            dataLists?.accountId?.event?.username ?
                                                                dataLists?.accountId?.event?.username : "-"
                                                        }
                                                        {isEmailCopy ? (
                                                            <LibraryAddCheck className="tooltip-container-checkCopy-icon-username" />
                                                        ) : (
                                                            <Tooltip
                                                                onClick={() => handleCopyToClipboardEmail(dataLists?.accountId?.event?.email)}
                                                                title="Copy"
                                                                className="tooltip-container-username"
                                                            >
                                                                <RiFileCopy2Fill />
                                                            </Tooltip>
                                                        )}
                                                    </div>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow>
                                                <StyledTableCell
                                                    component="th"
                                                    scope="row"
                                                    sx={thclass}
                                                >
                                                    Password
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <div
                                                        style={{
                                                            width: "100%",
                                                            minWidth: "max-content",
                                                        }}
                                                    >
                                                        <div className="flex">
                                                            <div className="flex">
                                                                <div
                                                                    style={{
                                                                        width: "fit-content",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        flex: "wrap",
                                                                        wordBreak: "break-all",
                                                                    }}
                                                                >
                                                                    {indexNum === dataLists?._id ? (
                                                                        <>
                                                                            {loadPass ? (
                                                                                <img src={LoadPassword} alt="" />
                                                                            ) : (
                                                                                <>
                                                                                    {hidePassword
                                                                                        ? dataLists?.accountId?.password
                                                                                        : "*******"}
                                                                                </>
                                                                            )}
                                                                            <VisibilityOff
                                                                                style={{
                                                                                    cursor: "pointer",
                                                                                    fontSize: "1.3rem",
                                                                                    color: "#002986",
                                                                                }}
                                                                                onClick={() =>
                                                                                    dataLists?.status === "Completed" ? handleNothing : handleShowhiddenPassword(dataLists)
                                                                                }
                                                                            />
                                                                            {isCopy ? (
                                                                                <LibraryAddCheck className="tooltip-container-checkCopy-icon" />
                                                                            ) : (
                                                                                <Tooltip
                                                                                    onClick={() => handleCopyToClipboardPassword(dataLists?.accountId?.password)}
                                                                                    title="Copy"
                                                                                    className="tooltip-container"
                                                                                >
                                                                                    <RiFileCopy2Fill />
                                                                                </Tooltip>
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <HiDotsHorizontal
                                                                                style={{
                                                                                    margin: "0px",
                                                                                    padding: "0px",
                                                                                    position: "relative",
                                                                                    left: "2px",
                                                                                    fontSize: "30px",
                                                                                }}
                                                                            />
                                                                            <HiDotsHorizontal
                                                                                style={{ fontSize: "30px" }}
                                                                            />
                                                                            <Visibility
                                                                                style={{
                                                                                    cursor: "pointer",
                                                                                    fontSize: "1.3rem",
                                                                                    color: "#002986",
                                                                                }}
                                                                                onClick={() =>
                                                                                    dataLists?.status === "Completed" ? handleNothing : handleShowhiddenPassword(dataLists)
                                                                                }
                                                                            />
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow>
                                                <StyledTableCell
                                                    component="th"
                                                    scope="row"
                                                    sx={thclass}
                                                >
                                                    Event Name
                                                </StyledTableCell>
                                                <StyledTableCell>{dataLists?.vbdEventId?.event_name ?? "-"}</StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow>
                                                <StyledTableCell
                                                    component="th"
                                                    scope="row"
                                                    sx={thclass}
                                                >
                                                    Event Date
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <DateTimeFormat date={dataLists?.vbdEventId?.dateformatted ?? null} />
                                                </StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow>
                                                <StyledTableCell
                                                    component="th"
                                                    scope="row"
                                                    sx={thclass}
                                                >
                                                    Status
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <div style={{ display: "flex" }}>
                                                        <TicketStatus status={dataLists?.status} />
                                                    </div>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow>
                                                <StyledTableCell
                                                    component="th"
                                                    scope="row"
                                                    sx={thclass}
                                                >
                                                    Notes
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <p dangerouslySetInnerHTML={{ __html: dataLists?.notes }} />
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            {/* change states for ticket */}
            <Dialog
                onClick={handleBackdropClick}
                maxWidth="sm"
                open={isOpenModal}
                sx={{
                    "& .MuiDialog-paper": {
                        width: "100%",
                        maxWidth: "500px"
                    },
                }}
            >
                <DialogTitle>Change Status</DialogTitle>
                <DialogContent>
                    <IconButton
                        aria-label="close"
                        onClick={(e) => handleCloseModalChangeStates(e)}
                        sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            color: (theme) => theme.palette.grey[500],
                            p: 1,
                        }}
                    >
                        <Cancel />
                    </IconButton>
                    <DialogContentText
                        sx={{
                            fontFamily: "Barlow",
                            fontWeight: "500",
                            fontSize: "18px",
                            color: "black",
                        }}
                    >
                        <Grid container spacing={1}>
                            <Grid item size={{ xs: 12, sm: 4, md: 4, lg: 12 }} sx={{ width: "100%" }}>
                                <FormControl fullWidth>
                                    <label className="Account_details_box-manage-license">
                                        Status
                                    </label>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        size="small"
                                        onChange={(e) => handleOnchnageTicketStatus(e)}
                                        value={statusTicket}
                                        defaultValue="all"
                                        sx={{
                                            textAlign: "left",
                                        }}
                                    >
                                        <MenuItem value="Error - Password Incorrect">Error - Password Incorrect</MenuItem>
                                        <MenuItem value="Error - Password Reset">Error - Password Reset</MenuItem>
                                        <MenuItem value="Error - Verification Required">Error - Verification Required</MenuItem>
                                        <MenuItem value="Completed">Completed</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={isLoading}
                        onClick={(e) => handleCloseModalChangeStates(e)}
                        className={isLoading ? "refresh-btn-disbaled" : 'refresh-btn'}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => handleTicketStatusUpdate()}
                        disabled={isLoading}
                        className={isLoading ? "refresh-btn-disbaled" : 'refresh-btn'}
                        startIcon={isLoading ? <CircularProgress size={18} sx={{ color: "#BCBCBC !important" }} /> : null}
                    >
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
            {/* sync Confirmation Modal */}
            <ConfirmationModal
                handleAPIRequest={isDisbaled ? handleNothing : syncAPiCall}
                isOpen={syncModal}
                handleClose={isDisbaled ? handleNothing : handleCloseDeleteConfirmationModel}
                message={"Are you sure you want to sync the event tickets?"}
                title={"Sync Event"}
                buttonText={"Yes"}
                buttonText2={"No"}
            />
            {/* scan Confirmation Modal */}
            <ConfirmationModal
                handleAPIRequest={isDisbaled ? handleNothing : scanAPiCall}
                isOpen={scanModal}
                handleClose={isDisbaled ? handleNothing : handleCloseDeleteConfirmationModel}
                message={"Are you sure you want to scan the event for tickets?"}
                title={"Scan Event"}
                buttonText={"Yes"}
                buttonText2={"No"}
            />
            {/* alert message */}
            <AlertMessage
                open={alertMessage.openAlert}
                message={alertMessage.message}
                severity={alertMessage.alertSeverity}
                closeCall={hideAlert}
            />
        </>
    )
}
export default SupportTicketDetails;